import React from 'react';
import NavBar from '../components/navbar';
import Header from '../components/header';
import Footer from '../components/footer';

import {Link} from 'react-router-dom';

import subtitulo_left from '../assets/icon/subtitulo_left.png';
import subtitulo_right from '../assets/icon/subtitulo_right.png';
import emergencias from '../assets/img/servicios/EMERGENCIA.jpg';
import reahabilitacion from '../assets/img/servicios/REHABILITACION.jpg';
import tomografia from '../assets/img/servicios/tomografia.jpg';
import neurologia from '../assets/icon/neurology.png';
import Centro_quirurgico from '../assets/icon/centro-quirurgico.png';
import lConsulta_externa from '../assets/icon/consulta-externa.png';
import gastroenterologia from '../assets/icon/stomach.png';

import MapContainer from '../components/ubicacion';

import '../styles/home.css';

class HomeComponent extends React.Component {

    
    constructor(props){
        super(props);
        this.state = {
            value: '',
            noticias_eventos:[],
            count:1
        };
        this.buscarEventosNoticias();
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('http://clinicasanantonio.pe/san-antonio-sistem/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.registrarVisita('home');
    }

    buscarEventosNoticias(){
        var data = new FormData();
        fetch('http://clinicasanantonio.pe/san-antonio-sistem/API-REST/controller/noticia.php?op=listar-noticias-eventos-web',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            data=JSON.parse(data);
            this.setState({'noticias_eventos':data});
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }


    listarNoticiasEventos=(data)=>{

        let nuevo_count=this.state.count;

        if(this.state.count==1){
            this.state.count=nuevo_count+1;
            return(
                <div className="carousel-item active">
                 
                            <div className="img" style={{ backgroundImage: `url(http://clinicasanantonio.pe/san-antonio-sistem/assets/galeria/${data[3]})` }}> 
                            </div>
                       
                            <div className="contenedor_texto">
                                <h3>{data[1]}</h3> 
                                <p>{data[2].substring(200,-1)} ... <a href="#" style={{color:'#b9c74b'}}>Leer mas</a></p>
                            </div>
                  

                    
                </div>
            )
        }else{
            return(
                <div className="carousel-item">
                    <div className="img" style={{ backgroundImage: `url(http://clinicasanantonio.pe/san-antonio-sistem/assets/galeria/${data[3]})` }}> 
                    </div>
                    <div className="contenedor_texto">
                        <h3>{data[1]}</h3> 
                        <p>{data[2]}</p>
                    </div>
                </div>
            )
        }

        
    }


    actualizarLista(){
        
        

        return(

            this.state.noticias_eventos.map(data =>{

                    return(this.listarNoticiasEventos(data));
                    
                }
                
                
            )
        )

    
    }


    render() {
        return (

            <div>
                 <NavBar/>
                 <Header/>
                 
                <div className="contenido_home">


                    <div className="servicios_home">

                        <div className="subtitulo">
                            <div className="subtitulo_marcas">
                                <img className="izquierda" src={subtitulo_left} />
                                <img className="derecha" src={subtitulo_right} />
                            </div>
                            <h1>Nuestros Servicios</h1>
                        </div>

                        <div className="contenido_servicios_home">

                            <div className="descripcion">
                                <p>
                                    Siéntete tranquiloo, con nuestros 13 <b>servicios</b>. Tienes todo lo que tu salud necesita.
                                </p>

                            </div>

                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                    <div className="card esp1">
                                        <img className="card-img-top img-fluid" alt="..." src={tomografia} />
                                        <div className="card-body">
                                            <p className="card-text">Tomografía</p>
                                        </div>
                                    </div>  
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                    <div className="card esp2">
                                        <img className="card-img-top img-fluid" alt="..." src={emergencias} />
                                        <div className="card-body">
                                            <p className="card-text">Emergencias</p>
                                        </div>
                                    </div>  
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                    <div className="card esp3">
                                        <img className="card-img-top img-fluid" alt="..." src={reahabilitacion} />
                                        <div className="card-body">
                                            <p className="card-text">Fisioterapia</p>
                                        </div>
                                    </div>  
                                </div>
                            </div>

                            <div className="row enlace_servicios">
                                    <div className="mx-auto">
                                        <Link className="btn btn-primary" to="servicios" >VER TODOS LOS SERVICIOS</Link>
                                    </div>
                            </div>


                        </div>

                    </div>



                    <div className="especialidades">

                        <div className="subtitulo">
                            <div className="subtitulo_marcas">
                                <img className="izquierda" src={subtitulo_left} />
                                <img className="derecha" src={subtitulo_right} />
                            </div>
                            <h1>Especialidades</h1>
                        </div>

                        <div className="especialidades_contenido">
                            <div className="fondo">
                            </div>

                            <div className="capa">
                                
                            </div>

                            <div className="contenido">
                                
                                
                               { /* <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional1}/>
                                            </div>
                                            <h2>Dr. Carlos Bueno Castillo</h2>
                                            <label>Esp. en Neurocirugía</label>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional2}/>
                                            </div>
                                            <h2>Dra. Rosa Bueno S.</h2>
                                            <label>Esp. en Gastroenterología</label>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional3}/>
                                            </div>
                                            <h2>Dr. Paolo Medina V.</h2>
                                            <label>Esp. en Urología</label>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional4}/>
                                            </div>
                                            <h2>Dra. Rosalyn Peralta C.</h2>
                                            <label>Esp. en Cardiología</label>
                                        </div>
                                    </div>

                                </div> */
                                }

                                

                            <div className="items_especialidades">

                                <div className="row">

                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                        <div className="contenedor_item">
                                            <div className="item">
                                                <img src={neurologia}/>
                                            </div>
                                            <p>Neurología</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                        <div className="contenedor_item">
                                            <div className="item">
                                                <img src={Centro_quirurgico}/>
                                            </div>
                                            <p>Cirugía</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                        <div className="contenedor_item">
                                            <div className="item">
                                                <img src={lConsulta_externa}/>
                                            </div>
                                            <p>Cardiología</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                        <div className="contenedor_item">
                                            <div className="item">
                                                <img src={gastroenterologia}/>
                                            </div>
                                            <p>Gastroenterología</p>
                                        </div>
                                    </div>


                                </div>


                            </div>


                                <div className="row enlace_especialidades">
                                    <div className="mx-auto">
                                        <Link className="btn btn-primary" to="especialidades" >VER MAS ESPECIALIDADES</Link>
                                    </div>
                                </div>

                            </div>
                        </div>

                        
                    </div>


                    <div className="noticias">


                        <div className="subtitulo">
                            <div className="subtitulo_marcas">
                                <img className="izquierda" src={subtitulo_left} />
                                <img className="derecha" src={subtitulo_right} />
                            </div>
                            <h1>Noticias y Eventos</h1>
                        </div>

                        <div className="row contenido_noticias">

                            <div className="contenedor_carousel_noticias">


                                <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel" data-pause="false">
                                    <ol className="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators2" data-slide-to="0" className="active"></li>
                                        <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
                                    </ol>
                                    <div className="carousel-inner">

                                        {
                                            this.actualizarLista(this.actualizarLista)
                                         
                                        }

                                        
                             
                                      
                                    </div>
                                        <a className="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                        
                                </div>


                                </div>
                            
                            {
                                /*
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="row no-gutters">
                                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                        <div className="img-noticia1">
                                            <img src={noticia}/>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-lg-7 col-md-12 col-sm-12">
                                        <div className="card-body">
                                            <h5 className="card-title">DESPISTAJE DE CÁNCER DE PRÓSTATA</h5>
                                            <p className="card-text">Toma consciencia de hacerte un autoexamen preventivo...</p>
                                            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                            <div>
                                                <a name="" id="" className="btn btn-primary" href="#" role="button">Ver más...</a>                
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="row no-gutters">
                                    <div className="col-md-5 col-lg-5 col-md-12 col-sm-12">
                                        <div className="img-noticia2">
                                            <img src={noticia2}/>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-lg-7 col-md-12 col-sm-12">
                                        <div className="card-body">
                                            <h5 className="card-title">¡GANEMOS AL CÁNCER DE MAMA!</h5>
                                            <p className="card-text">La mamografía puede salvar tu vida, detecta en las etapas iniciales...</p>
                                            <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                                            <div>
                                                <a name="" id="" className="btn btn-primary" href="#" role="button">Ver más...</a>                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */
                            }

                            

                        </div>
                    </div>

                        <div className="subtitulo">
                            <div className="subtitulo_marcas">
                                <img className="izquierda" src={subtitulo_left} />
                                <img className="derecha" src={subtitulo_right} />
                            </div>
                            <h1>Ubicación</h1>
                        </div>


                    <MapContainer />


                    
                    <Footer/>

                </div>

                

                
            </div>
        );
    }
}

export default HomeComponent;