import React from 'react';

import NavBar from '../components/navbar';
import Footer from '../components/footer';

import MapContainer from '../components/ubicacion';

import '../styles/contacto.css';


class ContactoPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {value: ''};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        fetch('http://clinicasanantonio.pe/san-antonio-sistem/API-REST/controller/contacto.php?op=registrar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            if(data=="true"){
                alert("Sus datos fueron enviados. Nos pondremos en comunicación con usted.");
                window.location.reload();
            }else{
                alert("Ocurrio un problema, revise su conexión.");
            }
        },
        (error)=>{
            console.log(error);
        }
        );
       
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.registrarVisita('contacto');
      }
      
    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('http://clinicasanantonio.pe/san-antonio-sistem/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }



    render(){
        return(

            <div>

                <NavBar />

                <div className="contenedor_contacto">

                    <div className="fondo_contenedor_contacto">

                        <div className="contenedor_formulario">
                            <div className="row contenedor_caja_formulario">
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                    <form className="row formulario_contacto" onSubmit={this.handleSubmit}>

                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <label className="mensaje_indicacion_formulario">
                                                Ingresa tus datos y nos comunicaremos contigo.
                                            </label>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 contenedor_input_contacto">
                                            <input type="text" className="form-control" name="nombres" id="nombres" placeholder="Nombres" required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 contenedor_input_contacto">
                                            <input type="email" className="form-control" name="correo" id="correo" placeholder="Email" required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 contenedor_input_contacto">
                                            <input type="text" className="form-control" name="telefono" id="telefono" placeholder="Teléfono" required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 contenedor_input_contacto">
                                            <textarea type="text" className="form-control" name="observacion" id="observacion" placeholder="Mensaje" rows="2" required></textarea>
                                        </div>


                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="row">
                                                <div className="mx-auto">
                                                    <button className="btn btn-primary" type="submit" id="btn_enviar_contacto"><i className="fas fa-share-square"></i> Enviar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                    <div className="row datos_clinica">
                                        <div className="mx-auto contenedor_datos">
                                            <p className="dato_clinica">
                                                <i className="fas fa-home"></i> Dirección
                                            </p>

                                            <p className="informacion_clinica">Dirección: Av. Larco 630 Urb. San Andrés

                        13008, Trujillo - La Libertad</p>

                                            <p className="dato_clinica">
                                                <i className="fas fa-phone"></i> Teléfono</p>

                                            <p className="informacion_clinica"><a href="tel: 044-204767"> 044-202597</a> / <a href="tel: 044-204767"> 044-224767</a></p>

                                            <p className="dato_clinica">
                                                <i className="fas fa-envelope"></i> Email</p>

                                            <p className="informacion_clinica">contacto@clinicasanantonio.pe</p>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>




                    </div>

                        <MapContainer />
                        <Footer />
                </div>


               

                


            </div>

        );
    }

}
export default ContactoPage;