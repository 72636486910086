import React from 'react';
import '../styles/header.css';


class HeaderClinica extends React.Component {

  render() {
    return (


            <div className="contenedor_header">


                          <div className="carousel">
                                <div className="img_header_clinica"> 
                                  <p>LA CLINICA</p>   
                               </div>

                               

                               
                          </div>

                    
            </div>


    );
  }
}

export default HeaderClinica;
