import React from 'react';
import '../styles/header.css';


class Header extends React.Component {

  render() {
    return (


            <div className="contenedor_header">


                <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel" data-pause="false">
                      <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                      </ol>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="img1"> 
                               
                          </div>

                          <div className="contenedor_texto">
                            <h1>Clinica San Antonio</h1> 
                            <p>Comprometidos con la calidad, seguridad, y <br/> el cuidado de nuestros pacientes.</p>

                          </div>
                          
                          
                        </div>
                        <div className="carousel-item">
                          <div className="img2"> 
                            
                          </div>

                          <div className="contenedor_texto">
                            <h1>Servicios de Calidad</h1> 
                            <p>Con equipos de última tecnología y <br/> especialistas continuamente capacitados.</p>

                          </div>
                          
                        </div>
                        <div className="carousel-item">

                          <div className="img3"> 
                            
                          </div>

                          <div className="contenedor_texto">
                            <h1>Atención Integral</h1> 
                            <p>Contando con el respaldo de un equipo de médicos <br/> de reconocida trayectoria en cada una sus especialidades</p>

                          </div>

                        </div>
                      </div>
                      
                      {
                        /*
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                      </a>
                        */
                      }
                  </div>
            

            </div>


    );
  }
}

export default Header;
