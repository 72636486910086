import React from 'react';

import NavBar from '../components/navbar';
import HeaderEspecialidades from '../components/header-especialidades';
import Footer from '../components/footer';


import {Link} from 'react-router-dom';

import cardiologia from '../assets/img/tratamientos/CARDIOLOGIA.jpg'; 
import cirugia from '../assets/img/tratamientos/CIRUGIA.jpg'; 
import dermatologia from '../assets/img/tratamientos/DERMATOLOGIA.jpg';
import reumatologia from '../assets/img/tratamientos/REUMATOLOGIA.jpg';
import ginecologia from '../assets/img/tratamientos/GNECOLOGIA.jpg';
import hematologia from '../assets/img/tratamientos/HEMATOLOGIA.jpg';
import neurocirugia from '../assets/img/tratamientos/NEUROCIRUGIA.jpg';
import odontologia from '../assets/img/tratamientos/ODONTOLOGIA.jpg';
import oftalmologia from '../assets/img/tratamientos/OFTALMOLOGIA.jpg';
import rehabilitacion from '../assets/img/tratamientos/REHABILITACION.jpg';
import cardiovascular from '../assets/img/tratamientos/CARDIOVASCULAR.jpg';
import neurologia from '../assets/img/tratamientos/NEUROLOGIA.jpg';

import logo_tumi from '../assets/img/logo_tumi.png';

import '../styles/especialidades.css';

class EspecialidadesPage extends React.Component{


    constructor(props){
        super(props);
        this.state = {value: ''};
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('http://clinicasanantonio.pe/san-antonio-sistem/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.registrarVisita('especialidades');
    }

    render(){
        return(

            <div>

                <NavBar/>
                <HeaderEspecialidades/>
                
                <div className="contenido_especialidades">

                    <div className="especialidades">

                        <div className="introduccion">

                            <div className="logo_tumi">
                                    <img src={logo_tumi} />
                            </div>
                            <h3>SIÉNTETE TRANQUILO, CON NUESTRAS <label> ESPECIALIDADES</label> TIENES TODO LO QUE TU SALUD NECESITA.</h3>
                        </div>

                    </div>


                    <div className="lista_especialidades">

                        <div className="fondo">

                        </div>

                        <div className="capa">
                            
                        </div>

                        <div className="items_especialidades">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={cardiologia}/>
                                            <div className="etiqueta">
                                                <h4>Cardiología</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={cirugia}/>
                                            <div className="etiqueta">
                                                <h4>Cirugía</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={dermatologia}/>
                                            <div className="etiqueta">
                                                <h4>Dermatología</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={reumatologia}/>
                                            <div className="etiqueta">
                                                <h4>Reumatología</h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={ginecologia}/>
                                            <div className="etiqueta">
                                                <h4>Ginecología</h4>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={hematologia}/>
                                            <div className="etiqueta">
                                                <h4>Hematología</h4>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={neurocirugia}/>
                                            <div className="etiqueta">
                                                <h4>Neurocirugía</h4>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={odontologia}/>
                                            <div className="etiqueta">
                                                <h4>Odontología</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={oftalmologia}/>
                                            <div className="etiqueta">
                                                <h4>Oftalmología</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={rehabilitacion}/>
                                            <div className="etiqueta">
                                                <h4>Rehabilitación</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={cardiovascular}/>
                                            <div className="etiqueta">
                                                <h4>Cardio - Vascular</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 columna">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={neurologia}/>
                                            <div className="etiqueta">
                                                <h4>Neurología</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                            <div className="row enlace_especialidades">
                                    <div className="mx-auto">
                                        <Link className="btn btn-primary" to="servicios" >VER NUESTROS SERVICIOS</Link>
                                    </div>
                            </div>

                            

                            {/*
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={laboratorio}/>
                                        </div>
                                        <p>Laboratorio Clinico</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={laboratorio}/>
                                        </div>
                                        <p>Neurofisiología</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={laboratorio}/>
                                        </div>
                                        <p>Odontología</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={laboratorio}/>
                                        </div>
                                        <p>Pediatría</p>
                                    </div>
                                </div>
                                
                            </div>
                           
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={laboratorio}/>
                                        </div>
                                        <p>Radiología</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={laboratorio}/>
                                        </div>
                                        <p>Tomografía</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    <div className="contenedor_item">
                                        <div className="item">
                                            <img src={laboratorio}/>
                                        </div>
                                        <p>Unidad de cuidados intensivos UCI</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                    
                                </div>
                                
                            </div>

                           */}
                        </div>

                    </div>


                    <Footer />

                </div>


            </div>

        );
    }

}
export default EspecialidadesPage;