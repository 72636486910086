import React from 'react';

import NavBar from '../components/navbar';
import HeaderStaff from '../components/header-staff';
import Footer from '../components/footer';

import {Link} from 'react-router-dom';

import profesional1 from '../assets/img/servicios/ambulancia.jpg';
import profesional2 from '../assets/img/servicios/banco_sangre.jpg';
import profesional3 from '../assets/img/servicios/sala_operaciones.jpg';
import profesional4 from '../assets/img/servicios/tomografia.jpg';
import emergencia from '../assets/img/servicios/EMERGENCIA.jpg';
import farmacia from '../assets/img/servicios/FARMACIA.jpg';
import fisioterapia from '../assets/img/servicios/FISIOTERAPIA.jpg';
import radiologia from '../assets/img/servicios/RADIOLOGIA-ECOGRAFIA.jpg';
import rehabilitacion from '../assets/img/servicios/REHABILITACION.jpg';
import traumashock from '../assets/img/servicios/TRAUMASHOCK.jpg';
import uci from '../assets/img/servicios/UCI.jpg';

import logo_tumi from '../assets/img/logo_tumi.png';

import '../styles/staff.css';


class StaffPage extends React.Component{


    constructor(props){
        super(props);
        this.state = {value: ''};
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('http://clinicasanantonio.pe/san-antonio-sistem/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.registrarVisita('servicios');
    }

    render(){
        return(

            <div>

                <NavBar/>
                <HeaderStaff/>

                    <div className="contenido_staff">
            
                        <div className="staff">
                            <div className="introduccion">
                                <div className="logo_tumi">
                                    <img src={logo_tumi} />
                                </div>
                                <h3>SIÉNTETE TRANQUILO, CON NUESTROS <label> SERVICIOS</label> .</h3>
                            </div>


                            <div className="lista_staff">

                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional1}/>
                                            </div>
                                            <h2>Ambulancia</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional2}/>
                                            </div>
                                            <h2>Banco de Sangre</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional3}/>
                                            </div>
                                            <h2>Sala de Operaciones</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={profesional4}/>
                                            </div>
                                            <h2>Tomografía</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={emergencia}/>
                                            </div>
                                            <h2>Emergencia</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={farmacia}/>
                                            </div>
                                            <h2>Farmacia</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={fisioterapia}/>
                                            </div>
                                            <h2>Fisioterapia</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={radiologia}/>
                                            </div>
                                            <h2>Radiología - Ecografía</h2>
                                        </div>
                                    </div>


                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={rehabilitacion}/>
                                            </div>
                                            <h2>Rehabilitación</h2>
                                        </div>
                                    </div>

                                 
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={traumashock}/>
                                            </div>
                                            <h2>Traumashock</h2>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="profesional">
                                            <div className="imagen">
                                                <img src={uci}/>
                                            </div>
                                            <h2>UCI</h2>
                                        </div>
                                    </div>

                                </div>


                                <div className="row enlace_staff">
                                    <div className="mx-auto">
                                        <Link className="btn btn-primary" to="/especialidades" >VER NUESTRAS ESPECIALIDADES</Link>
                                    </div>
                                </div>


                            </div>


                        </div>

                

                    <Footer />
                    </div>


            </div>

        );
    }

}
export default StaffPage;