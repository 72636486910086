import React from 'react';
import NavBar from '../components/navbar';
import HeaderClinica from '../components/header-clinica';
import Footer from '../components/footer';

import subtitulo_left from '../assets/icon/subtitulo_left.png';
import subtitulo_right from '../assets/icon/subtitulo_right.png';

import mision from '../assets/icon/mision.png';
import valores from '../assets/icon/valores.png';
import vision from '../assets/icon/vision.png';

import convenio1 from '../assets/img/convenios/convenio1.png';
import convenio2 from '../assets/img/convenios/convenio2.png';
import convenio3 from '../assets/img/convenios/convenio3.jpg';
import convenio4 from '../assets/img/convenios/convenio4.jpg';
import convenio5 from '../assets/img/convenios/convenio5.png';
import convenio6 from '../assets/img/convenios/convenio6.jpg';


import logo_tumi from '../assets/img/logo_tumi.png';


import '../styles/clinica.css';


class ClinicaPage extends React.Component{


    constructor(props){
        super(props);
        this.state = {value: ''};
    }

    registrarVisita(nombre){
        var data = new FormData();
        data.append("pagina", nombre);
        fetch('http://clinicasanantonio.pe/san-antonio-sistem/API-REST/controller/visitas.php?op=agregar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log(data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.registrarVisita('clinica');
    }

    render(){
        return(

            <div>

                <NavBar/>
                <HeaderClinica/>

                <div className="contenido_clinica">

                    <div className="nosotros">

                        <div className="subtitulo">
                                <div className="subtitulo_marcas">
                                    <img className="izquierda" src={subtitulo_left} />
                                    <img className="derecha" src={subtitulo_right} />
                                </div>
                                <h1>Sobre Nosotros</h1>
                        </div>
                        
                        <div className="contenido_nosotros">
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                    <img className="logo_tumi" src={logo_tumi} />
                                </div>

                                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-4 col-sm-12">
                                            <div className="item_sobre_nosotros">
                                                <div className="imagen">
                                                    <img src={mision} />
                                                    <h6>Misión</h6>
                                                </div>
                                                
                                                <p>Promover la salud y calidad de vida de la colectividad trujillana y del norte del país; a partir de una atención medica profesional calificada, oportuna y personalizada.</p>

                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-md-4 col-sm-12">
                                            <div className="item_sobre_nosotros">
                                                <div className="imagen">
                                                    <img src={vision} />
                                                    <h6>Visión</h6>
                                                </div>
                                                <p>Ser una institución reconocida a nivel local y nacional; por brindar servicios de salud médico-quirúrgico en todas las especialidades, bajo un modelo basado en innovación, gestión de calidad y capacitación permanente.</p>

                                            </div>
                                        </div>


                                        <div className="col-xl-12 col-lg-12 col-md-4 col-sm-12">
                                            <div className="item_sobre_nosotros">
                                                <div className="imagen">
                                                    <img src={valores} />
                                                    <h6>Valores</h6>
                                                </div>
                                                <p>Promover la salud y calidad de vida de las familias y de la colectividad trujillana y del norte del país; a partir de una atención medica profesional calificada, oportuna y personalizada.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="historia">
                        
                        <div className="subtitulo">
                                <div className="subtitulo_marcas">
                                    <img className="izquierda" src={subtitulo_left} />
                                    <img className="derecha" src={subtitulo_right} />
                                </div>
                                <h1>Convenios</h1>
                        </div>

                        <div className="contenido_historia">
                            
                            <div className="row">

                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <div className="contenedor_item">
                                                <div className="item">
                                                    <img src={convenio1}/>
                                                </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <div className="contenedor_item">
                                                <div className="item">
                                                    <img src={convenio2}/>
                                                </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <div className="contenedor_item">
                                                <div className="item">
                                                    <img src={convenio3}/>
                                                </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <div className="contenedor_item">
                                                <div className="item">
                                                    <img src={convenio4}/>
                                                </div>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <div className="contenedor_item">
                                                <div className="item">
                                                    <img src={convenio5}/>
                                                </div>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <div className="contenedor_item">
                                                <div className="item">
                                                    <img src={convenio6}/>
                                                </div>
                                    </div>
                                </div>

                            </div>

                            {
                             /*
                            <div className="fila color1">
                                <div className="contenedor_imagen">
                                    <img src={historia1} />
                                </div>
                                <p>En el año 2000, realizaron nuestra primera inversión inmueble en Trujillo, adquirida por medio de un préstamo hipotecario iniciamos con un centro médico, en un área de aproximadamente 270 metros cuadrados, donde se implementó un consultorio médico para neurocirugía, un consultorio de fisioterapia y una pequeña farmacia, es decir en esta década logramos materializar parte de su proyecto familiar.</p>
                            </div>

                            <div className="fila color2">
                                <div className="contenedor_imagen responsive_img">
                                    <img src={historia1} />
                                </div>
                                <p>En el año 2006, que nace el proyecto, con el nombre de Clínica San Antonio S.A.C, formalmente constituida, bajo la dirección médica del Dr. Bueno Castillo y como Gerente general a su Esposa, la Dra. Elizabeth Sánchez Roncal, la empresa se regula bajo la normatividad de la Ley de las MYPES, cuyo propósito es de facilitar la inversión, impulsar el desarrollo productivo y el crecimiento empresarial. Un logro de la gestión fue establecer nuestro primer conviene fue con Pacifico Seguros y Pacifico EPS.</p>
                                <div className="contenedor_imagen original_img">
                                    <img src={historia1} />
                                </div>
                            </div>

                            <div className="fila color3">
                            <div className="contenedor_imagen">
                                    <img src={historia2} />
                                </div>
                                <p>Es fundamentalmente Neuroquirúrgica. En el 2015, se moderniza y amplía su infraestructura luego de un proceso de demolición, actualmente cuenta con 6 niveles(1 sótano y 5 pisos). Con relación a su infraestructura, este inició con alrededor de 250 metros cuadrados, luego en el año 2008 se amplia a 550 metros cuadrados para posteriormente en el año 2009 se adquiere un terreno de 500 metros, totalizando a la actualidad 1050 metros cuadrados de terrenos ubicado en la Av. Larco 630 y 620 de la Urb. San Andrés de Trujillo.</p>
                            </div>
                            */
                            }

                        </div>

                    </div>

                    <Footer />
                    
                </div>


            </div>

        );
    }

}
export default ClinicaPage;