import React from 'react';


export class MapContainer extends React.Component {
    render() {

    const estilos1={
        width:'100%',
        height:'auto',
    }

    const estilos2={
        maxWidth:'100%',
        height:'500px',
        marginTop:'20px'
    }

    return (
        <div style={estilos1}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1396.4705001284967!2d-79.03584969195936!3d-8.120306076029422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xff279db6ee904d0a!2sCl%C3%ADnica%20San%20Antonio!5e0!3m2!1ses-419!2spe!4v1580089226239!5m2!1ses-419!2spe" width="3000" height="450"  style={estilos2}></iframe>
        </div>
       )
    }
}
    
export default MapContainer;
