import React from 'react';
import '../styles/header.css';


class HeaderEspecialidades extends React.Component {

  render() {
    return (


            <div className="contenedor_header">


                          <div className="carousel">
                                <div className="img_header_especialidades"> 
                                <p>NUESTRAS ESPECIALIDADES </p>  
                               </div>
                          </div>

                    
            </div>


    );
  }
}

export default HeaderEspecialidades;
