import React from 'react';

import logo from '../assets/img/clinica_logo_blanco.png';

import '../styles/footer.css';

class Footer extends React.Component {



  render() {
    return (
        
         <footer>
  
                <div className="row segunda_fila">
                    <div className="col-xl-5">
                        
                        <div className="row">
                            <div className="mx-auto">
                                <img src={logo} />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4">
                        
                        <div className="row">
                            <div className="mx-auto contenedor_datos_scrubs">
                                <p>Av. Larco 630 Urb. San Andrés</p>
                                <p>13008, Trujillo - La Libertad</p>
                                <p>Teléfono: <a href="tel:044 202597">044-202597</a> / <a href="tel:044 224767">044-224767</a></p>
                                <p>contacto@clinicasanantonio.com</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-3">
                        
                        <div className="row">
                            <div className="mx-auto">
                                <div className="rrss_bar_rrss">
                                    <ul>
                                    <li><a href="https://www.facebook.com/clinica.sanantonio.trujillo/" target="_blank"><i className="fab fa-facebook-square"></i></a></li>
                                    <li><a href="https://www.facebook.com/clinica.sanantonio.trujillo/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="https://www.facebook.com/clinica.sanantonio.trujillo/"><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <div className="row primera_fila_footer">
                    <p>© 2020 <label>CLINICA SAN ANTONIO.</label> All rights reserved</p>
                </div>

        
         </footer>
      

    );
  }
}

export default Footer;
