import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

import HomeComponent from './pages/home';
import ClinicaPage from './pages/clinica';
import EspecialidadesPage from './pages/especialidades';
import ContactoPage from './pages/contacto';
import StaffPage from './pages/staff';

const NoMatch = ({ location }) => {

 console.log(location.pathname);

      if(location.pathname==="/home"){
        return <Route exact path={location.pathname} component={HomeComponent} />
      } else{
          if(location.pathname==="/clinica"){
            return <Route exact path={location.pathname} component={ClinicaPage} />
          }else{
            if(location.pathname==="/especialidades"){
              return <Route exact path={location.pathname} component={EspecialidadesPage} />
            }else{
              if(location.pathname==="/servicios"){
                return <Route exact path={location.pathname} component={StaffPage} />
              }else{
                if(location.pathname==="/contacto"){
                  return <Route exact path={location.pathname} component={EspecialidadesPage} />
                }else{
                  return <h1>La dirección {location.pathname} no fue encontrada en ....com</h1>
                }
              }
              
            }
              
          }
        }
      
}


function RouterApp() {

  
  return(
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={HomeComponent} />
            <Route exact path="/home" component={HomeComponent} />
            <Route exact path="/clinica" component={ClinicaPage} />
            <Route exact path="/especialidades" component={EspecialidadesPage} />
            <Route exact path="/contacto" component={ContactoPage} />
            <Route exact path="/servicios" component={StaffPage} />
            <Route component={NoMatch} />
        </Switch>
    </BrowserRouter>
  );
}

export default RouterApp;
