import React from 'react';
import logo from '../assets/img/clinica_logo.png';
import * as $ from 'jquery';

import {Link} from 'react-router-dom';

import '../styles/navbar.css';

class NavBar extends React.Component {
  constructor(props){
      super(props);
      this.state = {value: ''};
      this.alerta = this.alerta.bind(this);


      $(document).ready(()=>{
        
          $(window).scroll(function() {

      
              if ($(window).scrollTop() > 10) {
                
               
                $('.logo_nav').stop().animate({'width':'150px'},300);
                  
      
              } else {
                //$('ul li .nav-link').css('color','#BF8F00');
            
                $('.logo_nav').stop().animate({'width':'200px'});
      
                }
                
            });

      })
  }

  alerta() {
    alert('El inicio de sesión para a resultados clinicos esta temporalmente suspendido.');
  }

  render() {
    return (

      <div className="contenedor_navbar">

        <div className="barra_rrss">
          <div className="telefono">
            <i className="fa fa-phone"></i> <label>Central Telefónica:</label> (044) 202597 - 224767
          </div>

          <div className="rrss_nav">
                <a href="https://www.facebook.com/clinica.sanantonio.trujillo/">
                  <div className="enlace_rrss">
                    <i className="fab fa-facebook-square"></i>
                  </div>
                </a>

                <a href="https://www.facebook.com/clinica.sanantonio.trujillo/">
                  <div className="enlace_rrss">
                    <i className="fab fa-instagram"></i>
                  </div>
                </a>


                <a href="https://www.facebook.com/clinica.sanantonio.trujillo/">
                  <div className="enlace_rrss">
                    <i className="fab fa-linkedin"></i>
                  </div>
                </a>
               
               
                
                
          </div>

        </div>
        
        <nav className="navbar navbar-expand-lg  navbar-light ">
          <a className="navbar-brand" href="#"><img src={logo} className="logo_nav" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <Link to="/home" className="nav-link"><i className="fas fa-home"></i> Home <span className="sr-only">(current)</span></Link>
              </li>
              <li className="nav-item">
                <Link to="/clinica" className="nav-link"><i className="fas fa-notes-medical"></i> La Clinica</Link>
              </li>

              <li className="nav-item">
                <Link to="/servicios" className="nav-link"><i className="fas fa-user-md"></i> Servicios</Link>
              </li>

              <li className="nav-item">
                <Link to="/especialidades" className="nav-link"><i className="fas fa-heartbeat"></i> Especialidades</Link>
              </li>
              
              {/*
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="https://www.facebook.com/pg/scrubsstoreperu/photos/?tab=album&album_id=767685426925054" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Servicios
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="https://www.facebook.com/pg/scrubsstoreperu/photos/?tab=album&album_id=767685426925054">Uniformes Médicos</a>
                  <a className="dropdown-item" href="https://www.facebook.com/pg/scrubsstoreperu/photos/?tab=album&album_id=767685426925054">Batas Médicas</a>
                  <a className="dropdown-item" href="https://www.facebook.com/pg/scrubsstoreperu/photos/?tab=album&album_id=767685426925054">Accesorios</a>
                </div>
              </li>
              */}
              

              <li className="nav-item">
                <Link to="/contacto" className="nav-link"><i className="fas fa-envelope"></i> Contacto</Link>
              </li>


            </ul>

            <div className="contenedor_inicio_sesion">
                <a name="" id="" className="btn btn-primary" href="#" onClick={this.alerta} role="button">Iniciar Sesión</a>                
            </div>

            

          </div>
            
        </nav>
        

      </div>

    );
  }
}

export default NavBar;
